import React from 'react'
import FeatherIcon from '../feather-icon'

const NavbarHamburger = ({ setOpenFn }) => (
  <>
    <div className="humberger-menu d-block d-lg-none pl--20">
      <span className="menutrigger text-white" onClick={() => setOpenFn(true)}>
        <FeatherIcon name="menu" />
      </span>
    </div>
    <div
      className="close-menu d-block d-lg-none"
      onClick={() => setOpenFn(false)}
    >
      <span className="closeTrigger">
        <FeatherIcon name="x" />
      </span>
    </div>
  </>
)

export default NavbarHamburger

import React from 'react'
import ScrollToTop from 'react-scroll-up'
import FeatherIcon from '../feather-icon/index'

const ScrollUp = () => (
  <ScrollToTop showUnder={200} style={{ zIndex: 2 }}>
    <div id="scrollUp">
      <FeatherIcon name="chevron-up" />
    </div>
  </ScrollToTop>
)

export default ScrollUp

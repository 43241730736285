import React from 'react'
import { Link } from 'gatsby'

import FeatherIcon from '../feather-icon'
import menuConfig from '../../../content/config/menu.yml'
import MeetingModal from '../meeting-modal'

import { trackEvent } from '../../services/matomo'

const Footer = () => {
  const onSocialClick = (socialName) => {
    trackEvent('FooterSocialItem', 'click', socialName)
  }

  return (
    <footer className="footer-area footer-default">
      <div className="footer-wrapper">
        <div className="row align-items-end row--0">
          <div className="col-lg-6">
            <div className="footer-left">
              <div className="inner">
                <span>{menuConfig.footer.cta_block.catch}</span>
                <h3>{menuConfig.footer.cta_block.answer}</h3>

                <MeetingModal
                  text={menuConfig.footer.cta_block.button}
                  origin="footer"
                  className="rn-button-style--2"
                />
              </div>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="footer-right">
              <div className="row">
                <div className="col-lg-6 col-sm-6 col-12">
                  <div className="footer-widget">
                    <ul className="ft-link">
                      {menuConfig.navbar.items.map((item, index) => (
                        <li key={`footer_menu_${index}`}>
                          <Link to={item.url || null}>{item.title}</Link>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
                <div className="col-lg-6 col-sm-6 col-12 mt_mobile--30">
                  <div className="footer-widget">
                    <ul className="ft-link">
                      {menuConfig.footer.items.map((item, index) => (
                        <li key={`footer_menu_${index}`}>
                          <Link to={item.url || null}>{item.title}</Link>
                        </li>
                      ))}
                    </ul>
                  </div>

                  <div className="social-share-inner">
                    <ul className="social-share social-style--2 d-flex justify-content-start liststyle mt--15">
                      {menuConfig.footer.social_items.map((item, index) => (
                        <li key={`social_share_${index}`}>
                          <a
                            target="_blank"
                            href={item.url}
                            title={item.title}
                            rel="noreferrer"
                            onClick={() => onSocialClick(item.title)}
                          >
                            <FeatherIcon name={item.icon} size="20" />
                          </a>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>

                <div className="col-lg-12">
                  <div className="copyright-text">
                    <p>{menuConfig.footer.copyright}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer

module.exports = {
  siteMetadata: {
    title: 'Accompagnement technique pour les startups, TPE et PME',
    description:
      'Concentrez-vous sur votre métier et laissez un expert vous fournir les outils nécessaires pour réussir.',
    siteUrl: 'https://davidderus.fr', // No trailing slash allowed!
    twitter: 'davidderus',
    author: 'David Dérus',
  },
  pathPrefix: '/',
  plugins: [
    {
      resolve: 'gatsby-source-filesystem',
      options: {
        path: `${__dirname}/content/pages/`,
        name: 'pages',
      },
    },
    {
      resolve: 'gatsby-source-filesystem',
      options: {
        path: `${__dirname}/content/images/`,
        name: 'images',
      },
    },
    {
      resolve: 'gatsby-source-filesystem',
      options: {
        path: `${__dirname}/content/assets/`,
        name: 'assets',
      },
    },
    {
      resolve: 'gatsby-transformer-remark',
      options: {
        plugins: [
          {
            resolve: `gatsby-remark-relative-images`,
          },
          {
            resolve: 'gatsby-remark-images',
            options: {
              maxWidth: 750,
              linkImagesToOriginal: false,
            },
          },
          {
            resolve: 'gatsby-remark-responsive-iframe',
            options: {},
          },
          'gatsby-remark-prismjs',
          'gatsby-remark-copy-linked-files',
          'gatsby-remark-smartypants',
        ],
      },
    },
    {
      resolve: `gatsby-plugin-manifest`,
      options: {
        name: 'Accompagnement technique pour les startups, TPE et PME',
        description:
          'Concentrez-vous sur votre métier et laissez un expert vous fournir les outils nécessaires pour réussir.',
        short_name: 'David Dérus',
        lang: 'fr',
        start_url: '/',
        background_color: '#ffffff',
        theme_color: '#0b1f3a',
        display: 'standalone',
        icon: 'static/img/favicon.svg',
      },
    },
    {
      resolve: 'gatsby-plugin-netlify',
      options: {
        mergeSecurityHeaders: true,
        mergeLinkHeaders: true,
        mergeCachingHeaders: true,
      },
    },
    'gatsby-plugin-catch-links',
    'gatsby-plugin-react-helmet',
    'gatsby-plugin-sass',
    'gatsby-plugin-sharp',
    'gatsby-plugin-sitemap',
    'gatsby-plugin-twitter',
    'gatsby-transformer-sharp',
    {
      resolve: `gatsby-plugin-purgecss`,
      options: {
        printRejected: false, // Print removed selectors and processed file names
        develop: false, // Enable while using `gatsby develop`
      },
    },
    {
      resolve: 'gatsby-plugin-matomo',
      options: {
        siteId: '2',
        matomoUrl: 'https://analytics.davidderus.fr',
        siteUrl: 'https://davidderus.fr',
        matomoPhpScript: 'matomo.php',
        matomoJsScript: 'matomo.js',
        disableCookies: true,
        requireConsent: false,
      },
    },
  ],
  flags: {
    PRESERVE_WEBPACK_CACHE: true,
    FAST_REFRESH: true,
  },
}

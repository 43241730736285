import React, { useState } from 'react'
import { Link } from 'gatsby'
import classNames from 'classnames/bind'

import NavbarItem from './item.js'
import NavbarHamburger from './hamburger.js'

import menuConfig from '../../../content/config/menu.yml'

import './main.scss'

const Navbar = () => {
  const [navbarOpen, setNavbarOpen] = useState(false)

  const navbarWrapperClass = classNames({
    'header-wrapper': true,
    'menu-open': navbarOpen,
  })

  const LogoElement = () => (
    <div className="logo">
      <Link to="/" className="navbar-brand">
        David Dérus
      </Link>
    </div>
  )

  return (
    <header className="header-area formobile-menu">
      <div className={navbarWrapperClass} id="header-wrapper">
        <div className="header-left">
          <LogoElement />
        </div>
        <div className="header-right">
          <div className="mainmenunav d-lg-block">
            <nav className="main-menu-navbar">
              <ul className="mainmenu">
                {menuConfig.navbar.items.map(({ ...itemOptions }, index) => (
                  <NavbarItem key={`navbar_item${index}`} {...itemOptions} />
                ))}
                <NavbarItem
                  title={menuConfig.navbar.contact.title}
                  url={menuConfig.navbar.contact.url}
                  classes="d-md-block d-lg-none"
                />
              </ul>
            </nav>
          </div>
          <div className="header-btn">
            <Link to={menuConfig.navbar.contact.url} className="rn-btn">
              <span>{menuConfig.navbar.contact.title}</span>
            </Link>
          </div>
          <NavbarHamburger setOpenFn={setNavbarOpen} />
        </div>
      </div>
    </header>
  )
}

export default Navbar

import React from 'react'
import { Helmet } from 'react-helmet'
import get from 'lodash/get'

const Meta = ({ site, title, description }) => {
  const pageTitle = title ?? get(site, 'title')
  const pageDescription = description ?? get(site, 'description')

  return (
    <Helmet
      title={pageTitle}
      meta={[
        { name: 'description', content: pageDescription },
        { name: 'twitter:card', content: 'summary' },
        {
          name: 'twitter:site',
          content: `@${get(site, 'twitter')}`,
        },
        { property: 'og:title', content: pageTitle },
        { property: 'og:type', content: 'website' },
        {
          property: 'og:description',
          content: pageDescription,
        },
      ]}
    />
  )
}
export default Meta

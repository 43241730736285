import React, { useState } from 'react'
import Modal from 'react-bootstrap/Modal'

import { trackEvent } from '../../services/matomo'

import './main.scss'

const MeetingModal = ({ text, origin, ...rest }) => {
  const [show, setShow] = useState(false)

  const sendContactModalEvent = (event) => {
    trackEvent('MeetingModal', event, origin)
  }

  const onClose = () => {
    setShow(false)
    sendContactModalEvent('close')
  }

  const onClick = () => {
    setShow(true)
    sendContactModalEvent('open')
  }

  // Dynamic CSS selectors: modal-dialog, modal-dialog-centered, fade, modal
  //                        modal-backdrop, show, modal-content, modal-header
  //                        modal-body, form-wrapper, contact-form--1, close
  //                        sr-only, modal-title, modal-lg
  return (
    <>
      <button onClick={onClick} {...rest}>
        {text}
      </button>

      <Modal
        show={show}
        onHide={onClose}
        centered
        size="lg"
        dialogClassName="MeetingModal"
      >
        <Modal.Header closeButton>
          <Modal.Title>{text}</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <iframe
            src="https://app.simplymeet.me/davidderus/prise-de-contact?is_widget=1&view=compact"
            style={{ width: '100%', height: '650px' }}
            frameBorder="0"
            scrolling="yes"
          ></iframe>
        </Modal.Body>
      </Modal>
    </>
  )
}

export default MeetingModal

export function setConsentGiven() {
  if (!window._paq) return

  _paq.push(['setConsentGiven'])
}

export function trackEvent(category, action, name, value) {
  if (!window._paq) return

  _paq.push(['trackEvent', category, action, name, value])
}

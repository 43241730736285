import React from 'react'

const featherSprite = require('feather-icons/dist/feather-sprite.svg')

const FeatherIcon = ({ name, size = '24', strokeWidth = '2' }) => {
  return (
    <div className="icon">
      <svg
        className="feather"
        width={size}
        height={size}
        fill="none"
        stroke="currentColor"
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <use xlinkHref={`${featherSprite}#${name}`} />
      </svg>
    </div>
  )
}

export default FeatherIcon

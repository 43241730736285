import React from 'react'

import 'typeface-poppins'
import 'typeface-montserrat'
import classNames from 'classnames/bind'

import Meta from '../meta'
import Navbar from '../navbar'
import Footer from '../footer'
import Breadcrumb from '../breadcrumb'
import ScrollUp from '../scroll-up'

import { siteMetadata } from '../../../gatsby-config'

import 'modern-normalize/modern-normalize.css'
import 'prismjs/themes/prism.css'

import '../../scss/theme.scss'

import 'animate.css/animate.css'

const Layout = ({
  title = siteMetadata.title,
  classes,
  hideBreadcrumb,
  breadcrumbParent,
  children,

  description: seoDescription,
  seoTitle,
}) => {
  const layoutClasses = classNames({ 'main-page': true }, classes)
  seoTitle = seoTitle && seoTitle.length > 0 ? seoTitle : title

  return (
    <div className={layoutClasses}>
      <Meta
        site={siteMetadata}
        title={[seoTitle, siteMetadata.author].join(' | ')}
        description={seoDescription}
      />

      <Navbar />

      {hideBreadcrumb || (
        <Breadcrumb
          title={title}
          seoTitle={seoTitle}
          parent={breadcrumbParent}
        ></Breadcrumb>
      )}

      <main className="page-wrapper">{children}</main>

      <Footer />

      <ScrollUp />
    </div>
  )
}

export default Layout

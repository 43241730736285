import React, { useState } from 'react'
import { Link } from 'gatsby'
import classNames from 'classnames/bind'

const NavbarItem = ({ title, url, subitems, classes }) => {
  const [itemOpen, setItemOpen] = useState(false)

  const itemClasses = classNames(
    { 'has-droupdown': subitems && subitems.length > 0 },
    classes
  )

  const navbarSubitems = (baseURL, subitems) => {
    const subitemsList = subitems.map(({ url, title }, index) => (
      <li key={`submenu_item_${index}`}>
        <Link to={`${baseURL}/${url || ''}`}>{title}</Link>
      </li>
    ))

    return (
      <ul className={classNames({ submenu: true, active: itemOpen })}>
        {subitemsList}
      </ul>
    )
  }

  const baseURL = url || ''

  return (
    <li className={itemClasses} onClick={() => setItemOpen(!itemOpen)}>
      <Link to={baseURL} className={itemOpen ? 'open' : null}>
        {title}
      </Link>

      {subitems && navbarSubitems(baseURL, subitems)}
    </li>
  )
}

export default NavbarItem

import React from 'react'
import { Link } from 'gatsby'

const Breadcrumb = ({ title, seoTitle, parent }) => (
  <div className="breadcrumb-area rn-bg-color pt--50 pb--100">
    <div className="container">
      <div className="breadcrumb-inner">
        <h1 className="title">{seoTitle}</h1>
        <ul className="page-list">
          <li>
            <Link to="/">Accueil</Link>
          </li>
          {parent && (
            <li>
              <Link to={parent.url}>{parent.title}</Link>
            </li>
          )}
          <li className="current-page">{title}</li>
        </ul>
      </div>
    </div>
  </div>
)

export default Breadcrumb
